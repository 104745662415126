import { CircleF } from "@react-google-maps/api";
import { Button, toast, types } from "@vilocnv/allsetra-core";
import { geozoneRadiusOptions, POLYGON_OPTIONS } from "app/data/constants";
import {
  createOrEditGeozoneThunk,
  getAllGeozonesThunk,
  resetActiveObjectAsMarker,
  setActiveGeozoneId,
  setActiveObjectAsMarker,
  setActiveObjectId
} from "app/features";
import GoogleMap from "components/maps/Map/v2/GoogleMap";
import { useMapState } from "components/maps/Map/v2/GoogleMap/MapStateContext";
import Markers from "components/maps/Map/v2/GoogleMap/Markers";
import { FC, useEffect, useMemo, useState } from "react";
import {
  AddIconWrapper,
  CustomTooltip,
  ObjectMapContainer,
  ObjectMapWrapper,
  RadiusSelectionBox,
  RadiusSelectionItemsWrapper,
  SelectRadiusTextBox
} from "../../ObjectDetailsSection.styled";
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme
} from "@mui/material";
import { AddGreenIcon, CancelRoundIcon } from "assets/icons";
import GeozoneForm from "components/forms/GeozoneForm/GeozoneForm";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { FormikHelpers } from "formik";
import { IAddGeozone } from "app/data/types";
import { SignalRService } from "app/data/services";
import {
  selectActiveGeozoneId,
  selectDrawerSelectedAccountId
} from "app/data/selectors";
import TrafficLayer from "components/maps/Map/v2/GoogleMap/TrafficLayer";
import Geozones from "components/maps/Map/v2/GoogleMap/Geozones";

import {
  createGeozoneObjectForNewlyDrawedCircle,
  signalRGenerateSuccessToastMessage
} from "app/data/helpers";
import DashboardMapTopRightSection from "components/maps/DashboardMap/DashboardMapTopRightSection";

interface Props {
  activeObject: types.IObject | null;
}

const ObjectDetailsGoogleMap: FC<Props> = ({ activeObject }) => {
  const { setShowClusters } = useMapState();

  useEffect(() => {
    setShowClusters(false);
  }, []);

  useEffect(() => {
    if (activeObject?.uniqueId) {
      setActiveObjectId(activeObject?.uniqueId);
    }
  }, [activeObject]);

  const { t } = useTranslation(["translation"]);
  const dispatch = useAppDispatch();

  const objectLocation = useMemo(
    () => ({
      lat: activeObject?.location?.latitude || 0,
      lng: activeObject?.location?.longitude || 0
    }),
    [activeObject]
  );

  const activeGeozoneId = useAppSelector(selectActiveGeozoneId);

  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const [circleSize, setCircleSize] = useState(10000);
  const [geozoneFormOpen, setGeozoneFormOpen] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [circle, setCircle] = useState<null>(null);
  //@ts-ignore
  const [createCircle, setCreateCircle] = useState(false);

  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent) => {
    if (setCircleSize) setCircleSize(Number(event.target.value));
  };

  const onFulfilledRequest = async (
    name: string,
    formikHelpers: FormikHelpers<IAddGeozone>
  ) => {
    formikHelpers.setSubmitting(false);
    formikHelpers.resetForm();
    setSubmitting(false);
    onCloseGeozoneForm();

    toast.success(
      signalRGenerateSuccessToastMessage(name, "Geozone", "created")
    );

    setCreateCircle(false);

    await dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
    await dispatch(setActiveGeozoneId(null));
  };

  const onSubmitHandler = async (
    values: IAddGeozone,
    formikHelpers: FormikHelpers<IAddGeozone>
  ) => {
    formikHelpers.setSubmitting(true);
    setSubmitting(true);

    //@ts-ignore
    const geozone = createGeozoneObjectForNewlyDrawedCircle(circle);

    const { type } = await dispatch(
      createOrEditGeozoneThunk({
        accountId: drawerSelectedAccountId,
        data: {
          ...values,
          assignedObjects: values.assignedToAllObjects
            ? []
            : values.assignedObjects,
          geoJson: geozone?.geoJson,
          isEnabled: true
        }
      })
    );

    if (type === "geozones/createOrEditGeozoneThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onFulfilledRequest(values.name, formikHelpers);
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.AccountGeozoneCreatedEvent ||
          event.eventName === types.BackendEventsEnum.AccountGeozoneUpdatedEvent
        ) {
          onFulfilledRequest(event.name, formikHelpers);

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
    }
  };

  const toggleGeozoneFormOpen = () => {
    setGeozoneFormOpen(!geozoneFormOpen);
  };

  const onCloseGeozoneForm = () => {
    toggleGeozoneFormOpen();
  };

  const objectRadius = useMemo(
    () =>
      activeObject?.location?.accuracy
        ? activeObject.location.accuracy > 15
          ? activeObject.location.accuracy
          : 0
        : 0,
    [activeObject]
  );

  useEffect(() => {
    if (activeObject) {
      dispatch(setActiveObjectAsMarker(activeObject));
    }

    return () => {
      dispatch(resetActiveObjectAsMarker());
    };
  }, [activeObject]);

  useEffect(() => {
    if (drawerSelectedAccountId) {
      dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
    }
  }, [drawerSelectedAccountId]);

  return (
    <ObjectMapContainer>
      <ObjectMapWrapper>
        <GoogleMap>
          <DashboardMapTopRightSection
            showEnableDisableClustersButton={false}
          />
          <Markers />
          <TrafficLayer />
          <Geozones />
          {createCircle && (
            <CircleF
              options={POLYGON_OPTIONS}
              radius={circleSize}
              center={objectLocation}
              onLoad={(circle) => {
                //@ts-ignore
                setCircle(circle);
              }}
              onUnmount={() => setCircle(null)}
            />
          )}
        </GoogleMap>
        <AddIconWrapper>
          <CustomTooltip title={t("titles.addGeozone")} placement="top-start">
            <Box>
              {createCircle ? (
                <CancelRoundIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setCreateCircle(false)}
                />
              ) : (
                <AddGreenIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setCreateCircle(true)}
                />
              )}
            </Box>
          </CustomTooltip>
          {createCircle && (
            <RadiusSelectionBox>
              <RadiusSelectionItemsWrapper>
                <Select
                  id="circle-dropdown"
                  variant={"standard"}
                  value={circleSize?.toString()}
                  label="Size"
                  onChange={handleChange}
                  sx={{ fontSize: "14px" }}
                  disableUnderline={true}
                >
                  {geozoneRadiusOptions.map((radiusItem) => {
                    return (
                      <MenuItem key={radiusItem.label} value={radiusItem.value}>
                        {radiusItem.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Button
                  id="submit-button"
                  theme={theme}
                  size={"small"}
                  text={t("buttonsText.save")}
                  variant="text"
                  sx={{ color: "#845AFC", fontSize: "14px" }}
                  onClick={() => {
                    setGeozoneFormOpen(true);
                  }}
                />
              </RadiusSelectionItemsWrapper>
              <SelectRadiusTextBox>
                {t("descriptions.selectGeozoneRadius")}
              </SelectRadiusTextBox>
            </RadiusSelectionBox>
          )}
          <Box></Box>
        </AddIconWrapper>
      </ObjectMapWrapper>
      <GeozoneForm
        open={geozoneFormOpen}
        onClose={onCloseGeozoneForm}
        onSubmitHandler={onSubmitHandler}
        submitting={submitting}
        activeGeozoneId={activeGeozoneId}
        activeObjectId={activeObject}
        isObjectDetailsPage={true}
      />
    </ObjectMapContainer>
  );
};

export default ObjectDetailsGoogleMap;
