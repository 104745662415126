import React from "react";
import { Tooltip } from "@mui/material";

interface MovingArrowLeftProps {
  label?: string;
}

export const MovingArrowLeft: React.FC<MovingArrowLeftProps> = ({ label }) => {
  return (
    <Tooltip title={label} arrow placement="top">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.33157 8.86187L14.1004 7.39434C14.1659 7.37498 14.2234 7.33511 14.2645 7.28055C14.3055 7.226 14.328 7.15968 14.3285 7.0914L14.3285 7.08453L14.3287 7.07766C14.3306 7.00951 14.3106 6.94254 14.2718 6.88654C14.2329 6.83054 14.1771 6.78844 14.1126 6.76638L14.1044 6.7636L3.1811 2.71143L9.33157 8.86187ZM3.33323 2.0568L14.3282 6.13555C14.5273 6.2036 14.6994 6.33354 14.8194 6.50637C14.9394 6.6792 15.0009 6.88587 14.9951 7.09618C14.9936 7.30746 14.9242 7.51265 14.7971 7.68146C14.6701 7.85027 14.4921 7.97376 14.2895 8.03368L9.50448 9.50618L8.03198 14.2912C7.97197 14.4937 7.84846 14.6717 7.67967 14.7987C7.51088 14.9257 7.30573 14.9952 7.09448 14.9968H7.07573C6.86857 14.9987 6.66606 14.9353 6.49698 14.8156C6.3279 14.6959 6.20084 14.526 6.13385 14.3299L2.0576 3.33555C2.05628 3.33231 2.05524 3.32897 2.05448 3.32555C1.99354 3.14857 1.9835 2.95804 2.02549 2.77564C2.04189 2.70443 2.06595 2.63558 2.09707 2.57021C2.14566 2.46813 2.21144 2.37455 2.29202 2.29376L2.29376 2.29203C2.37392 2.21207 2.46668 2.14668 2.56786 2.09819C2.63319 2.06689 2.70203 2.04263 2.77324 2.02605C2.95554 1.98361 3.1461 1.99318 3.32323 2.05368L3.33323 2.0568Z"
          fill="#00B2A3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7971 7.68146C14.9242 7.51265 14.9936 7.30746 14.9951 7.09618C15.0009 6.88587 14.9394 6.6792 14.8194 6.50637C14.6994 6.33354 14.5273 6.2036 14.3282 6.13555L3.33323 2.0568L3.32323 2.05368C3.1461 1.99318 2.95554 1.98361 2.77324 2.02605C2.59094 2.06849 2.42421 2.16125 2.29202 2.29376C2.15983 2.42628 2.06749 2.59324 2.02549 2.77564C1.9835 2.95804 1.99354 3.14857 2.05448 3.32555C2.05524 3.32897 2.05628 3.33231 2.0576 3.33555L6.13385 14.3299C6.20084 14.526 6.3279 14.6959 6.49698 14.8156C6.66606 14.9353 6.86857 14.9987 7.07573 14.9968H7.09448C7.30573 14.9952 7.51088 14.9257 7.67967 14.7987C7.84846 14.6717 7.97197 14.4937 8.03198 14.2912L9.50448 9.50618L14.2895 8.03368C14.4921 7.97376 14.6701 7.85027 14.7971 7.68146ZM14.1004 7.39434C14.1659 7.37498 14.2234 7.33511 14.2645 7.28055C14.3055 7.226 14.328 7.15968 14.3285 7.0914L14.3285 7.08453L14.3287 7.07766C14.3306 7.00951 14.3106 6.94254 14.2718 6.88654C14.2329 6.83054 14.1771 6.78844 14.1126 6.76638L14.1044 6.7636L3.1811 2.71143L9.33157 8.86187L14.1004 7.39434Z"
          fill="#00B2A3"
        />
      </svg>
    </Tooltip>
  );
};
