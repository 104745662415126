import useMovementRides from "hooks/useMovementRides";
import React, { createContext, useContext, type FC } from "react";

interface MovementRideContextType {
  showMovementRides: boolean;
  handleMovementRidesChange: (checked: boolean) => void;
}

const MovementRideContext = createContext<MovementRideContextType | undefined>(
  undefined
);

export const MovementRideProvider: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const movementRideState = useMovementRides();

  return (
    <MovementRideContext.Provider value={movementRideState}>
      {children}
    </MovementRideContext.Provider>
  );
};

export const useMovementRideContext = () => {
  const context = useContext(MovementRideContext);
  if (!context) {
    throw new Error(
      "useMovementRideContext must be used within MovementRideProvider"
    );
  }
  return context;
};
