import Stack from "@mui/material/Stack";
import { Badge, TableColumn, utils } from "@vilocnv/allsetra-core";
import { getResolvedAddress } from "app/data/helpers";
import LocationLink from "components/ui/links/LocationLink";
import { MovingIcon, IgnitionIcon } from "assets/icons";

/**
 * ACTIVITY REGISTRATION
 */
export const ACTIVITY_DATE_RENDERING: TableColumn<any>[] = [
  { selector: (row: any) => row.label }
];

export const ACTIVITY_TABLE_COLUMNS = (translator: any): TableColumn<any>[] => [
  {
    name: translator("tableHeading.ride", { ns: "tableHeadingsTranslation" }),
    selector: (row: any) => row?.name || "N/A",
    wrap: true
  },
  {
    name: translator("tableHeading.status", { ns: "tableHeadingsTranslation" })
  },
  {
    name: translator("tableHeading.startTime", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.endTime", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.rideDuration", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.startLocation", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.stopLocation", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.actions", {
      ns: "tableHeadingsTranslation"
    })
  }
];

export const ACTIVITY_INNER_TABLE_COLUMNS = (
  translator: any,
  resolvedAddress: Array<any>
): TableColumn<any>[] => [
  {
    selector: (row: any) => row?.rideId || "-"
  },
  {
    cell: (row: any) => {
      return (
        <Badge
          colorScheme={
            row.status !== "Active" && row.status !== "Activiteit"
              ? "error"
              : "success"
          }
        >
          {row.status !== "Active" && row.status !== "Activiteit"
            ? translator("tableHeading.passive", {
                ns: "tableHeadingsTranslation"
              })
            : translator("tableHeading.active", {
                ns: "tableHeadingsTranslation"
              })}
        </Badge>
      );
    },
    sortable: true
  },
  {
    cell: (row: any) => {
      return utils.formatDate({ date: row?.startDate });
    },
    wrap: true,
    sortable: true
  },
  {
    cell: (row: any) => {
      return utils.formatDate({ date: row?.endDate });
    },
    sortable: true
  },
  {
    cell: (row: any) => {
      if (!row.startDate || !row.endDate) return "N/A";

      const startDate = new Date(row?.startDate);
      const endDate = new Date(row?.endDate);
      //@ts-ignore
      const diff = endDate - startDate;
      const diffInHours = diff / (1000 * 60 * 60);
      const result = diffInHours.toFixed(2).replace(".", ",");
      return result;
    },
    sortable: true
  },
  {
    cell: (row: any) => {
      const start = getResolvedAddress(row, resolvedAddress, "start");

      if (start) {
        return start;
      }

      if (row.startLatitude && row.startLongitude) {
        return (
          <LocationLink
            latitude={row.startLatitude}
            longitude={row.startLongitude}
          />
        );
      }

      return "N/A";
    },
    sortable: true
  },
  {
    cell: (row: any) => {
      const end = getResolvedAddress(row, resolvedAddress, "end");

      if (end) {
        return end;
      }

      if (row.startLatitude && row.endLongitude) {
        return (
          <LocationLink
            latitude={row.endLatitude}
            longitude={row.endLongitude}
          />
        );
      }

      return "N/A";
    },
    sortable: true
  },
  {
    cell: (row: any) => {
      const ignitionTime = row?.ignitionTime
        ? row.ignitionTime.toFixed(2).replace(".", ",")
        : "0";
      const movementTime = row?.movementTime
        ? row.movementTime.toFixed(2).replace(".", ",")
        : "0";

      return (
        <Stack spacing={1}>
          {row.ignitionTime !== 0 && (
            <Badge colorScheme="default" icon={<IgnitionIcon />}>
              {translator("reports.ignitionOn")}: {ignitionTime}h
            </Badge>
          )}
          {row.movementTime !== 0 && (
            <Badge colorScheme="default" icon={<MovingIcon />}>
              {translator("reports.moving")}: {movementTime}h
            </Badge>
          )}
        </Stack>
      );
    }
  }
];
