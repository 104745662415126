import { Badge, TableColumn, utils } from "@vilocnv/allsetra-core";
import { getResolvedAddress } from "app/data/helpers";
import LocationLink from "components/ui/links";

const SESSION_TYPE_IDENTIFICATION = [
  { label: "None", key: 0 },
  { label: "Running Hours", key: 1 },
  { label: "Ignition", key: 2 },
  { label: "PTO", key: 3 }
];

export const WORKING_HOURS_HEADERS = [
  { label: "Object Name", key: "Object Name" },
  { label: "Session ID", key: "Session ID" },
  { label: "Time Start", key: "Time Start" },
  { label: "Time End", key: "Time End" },
  { label: "Duration", key: "Duration" },
  { label: "Start Location", key: "Start Location" },
  { label: "Stop Location", key: "Stop Location" },
  { label: "Start Value", key: "Start Value" },
  { label: "Stop Value", key: "Stop Value" },
  { label: "Difference", key: "Difference" },
  { label: "Session Type", key: "Session Type" }
];

const getColorForSessionType = (rideMode: number) => {
  const mode = SESSION_TYPE_IDENTIFICATION.find(
    (item) => item.key === rideMode
  );
  switch (mode?.key) {
    case 1:
      return "purple"; // Business ride
    case 2:
      return "info"; // Private ride
    case 3:
      return "success"; // Commuting ride
    case 0:
      return "grey";
    default:
      return "grey"; // Default color for unknown ride mode
  }
};

export const WORKING_HOURS_FIRST_LAYER_COLUMNS = (
  translator: any
): TableColumn<any>[] => [
  {
    name: translator("tableHeading.deviceID", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => row?.groupedInfo?.serialNumber || "N/A"
  },
  {
    name: translator("tableHeading.objectName", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => row?.groupedInfo?.name || "N/A",
    wrap: true
  },
  {
    name: translator("tableHeading.totalIgnitionHours", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => row?.groupedInfo?.contactHours.toFixed(1)
  },
  {
    name: translator("tableHeading.totalRunningHours", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => row?.groupedInfo?.operatingHours.toFixed(1)
  },
  {
    name: translator("tableHeading.totalPTOHours", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => row?.groupedInfo?.ptoHours.toFixed(1)
  }
];

export const WORKING_HOURS_SECOND_LAYER_COLUMNS = (
  translator: any
): TableColumn<any>[] => [
  {
    name: translator("tableHeading.sessionID", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => row.date,
    style: {
      minWidth: "200px"
    }
  },
  {
    name: translator("tableHeading.timeStart", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => calculateDeltaSum(row.data, 2),
    style: {
      minWidth: "150px"
    }
  },
  {
    name: translator("tableHeading.timeStop", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => calculateDeltaSum(row.data, 1),
    style: {
      minWidth: "150px"
    }
  },
  {
    name: translator("tableHeading.duration", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => calculateDeltaSum(row.data, 3),
    style: {
      minWidth: "150px"
    }
  },
  {
    name: translator("tableHeading.startLocation", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.stopLocation", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.startValue", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.stopValue", {
      ns: "tableHeadingsTranslation"
    }),
    style: {
      minWidth: "0px"
    }
  },
  {
    name: translator("tableHeading.difference", {
      ns: "tableHeadingsTranslation"
    }),
    style: {
      minWidth: "0px"
    }
  },
  {
    name: translator("tableHeading.sessionType", {
      ns: "tableHeadingsTranslation"
    }),
    style: {
      minWidth: "0px"
    }
  }
];

// used to calculato pto, running, ignition hrs for each record
export const calculateDeltaSum = (data: any[], sessionType: number) => {
  let sum = 0;
  data.forEach((item) => {
    if (item.sessionType === sessionType) {
      sum += item.calculatedDelta;
    }
  });
  switch (sessionType) {
    case 1:
      return `Running hours: ${sum.toFixed(1)}`;
    case 2:
      return `Ignition hours: ${sum.toFixed(1)}`;
    case 3:
      return `PTO hours: ${sum.toFixed(1)}`;
  }
};

export const WORKING_HOURS_DATA_LAYER = (resolvedAddress: Array<any>) => {
  return [
    { selector: (row: any) => row?.sessionId || "-" },
    {
      selector: (row: any) =>
        utils.formatDate({ date: row?.start, whenEmpty: "-" }),
      wrap: true
    },
    {
      selector: (row: any) =>
        utils.formatDate({ date: row?.end, whenEmpty: "-" }),
      wrap: true
    },
    { selector: (row: any) => row?.duration },
    {
      selector: (row: any) => {
        const start = getResolvedAddress(row, resolvedAddress, "start");

        if (start) {
          return start;
        }

        if (row.startLatitude && row.startLongitude) {
          return (
            <LocationLink
              latitude={row.startLatitude}
              longitude={row.startLongitude}
            />
          );
        }

        return "N/A";
      },
      wrap: true
    },
    {
      selector: (row: any) => {
        const end = getResolvedAddress(row, resolvedAddress, "end");

        if (end) {
          return end;
        }

        if (row.startLatitude && row.endLongitude) {
          return (
            <LocationLink
              latitude={row.endLatitude}
              longitude={row.endLongitude}
            />
          );
        }

        return "N/A";
      },
      wrap: true
    },
    { selector: (row: any) => row?.startValue?.toFixed(1) || "-" },
    { selector: (row: any) => row?.endValue?.toFixed(1) || "-" },
    {
      selector: (row: any) => {
        const [hours, minutes, seconds] = row?.duration.split(":").map(Number);
        const totalTimeInHours = hours + minutes / 60 + seconds / 3600;
        return totalTimeInHours.toFixed(1);
      }
    },
    {
      selector: (row: any) => {
        const mode = SESSION_TYPE_IDENTIFICATION.find(
          (item) => item.key === row?.sessionType
        );
        const color: any = getColorForSessionType(row.sessionType);
        return (
          <Badge colorScheme={color ?? "error"}>{mode?.label || "-"}</Badge>
        );
      }
    }
  ];
};
