import {
  convertDatesForFilterPayload,
  reportTransformTimeForAPI
} from "./commonReportHelpers";
import { convertToNormalTime } from "../reportCommonHelpers";

const transformPayloadValues = (values: any) => ({
  daysOfWeek:
    values.daysOfWeek?.length === 7 ? [] : values.daysOfWeek?.map(Number),
  rideModes: values?.rideModes?.map(Number) || []
});

const transformDateTimeValues = (values: any, dateValues: any) => {
  const timeValues = values.daysOfWeek
    ? {
        startTime: reportTransformTimeForAPI(
          values.startTime + ":00",
          dateValues.startDate
        ),
        endTime: reportTransformTimeForAPI(
          values.endTime + ":00",
          dateValues.endDate
        )
      }
    : {};

  return {
    ...values,
    ...timeValues
  };
};

const createBasePayload = (
  dateValues: any,
  values: any,
  apiPayload: any,
  reportType: number
) => ({
  ...(apiPayload ?? {}),
  startDate: dateValues.startDate,
  endDate: dateValues.endDate,
  ...transformDateTimeValues(values, dateValues),
  reportType,
  ...transformPayloadValues(values)
});

export const buildActivityReportPayload = async (
  apiPayload: any,
  values: any,
  dateValues: any
) => {
  const { formattedStartDate, formattedEndDate } =
    convertDatesForFilterPayload(dateValues);

  return createBasePayload(
    { startDate: formattedStartDate, endDate: formattedEndDate },
    values,
    apiPayload,
    6
  );
};

export const handleDateRange = (
  filterValues: any,
  dateValues: any,
  apiPayload: any,
  reportType: number
) => {
  const startDate = convertToNormalTime(dateValues.startDate);
  const endDate = convertToNormalTime(dateValues.endDate, true);

  return createBasePayload(
    { startDate, endDate },
    filterValues,
    apiPayload,
    reportType
  );
};
