import { FC, useEffect } from "react";
import { Theme } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { Modal, ModalProps } from "@vilocnv/allsetra-core";
import InnerForm from "./children/InnerForm";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { getInitialObjectsForDropdownListThunk } from "app/features";
import {
  selectDrawerSelectedAccountId,
  selectObjectsState
} from "app/data/selectors";

export type ReportsCommonFormProps = Pick<ModalProps, "open" | "onClose"> & {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  theme: Theme;
  dataLoading?: boolean;
  groups: Array<any>;
  objectTypes: Array<any>;
  objects: Array<any>;
  keys: Array<any>;
  submitting?: boolean;
  fetchOnDebounce: (value: string) => void;
  onChange?: () => void;
  isCO2InnerForm?: boolean;
  formInitialValues?: {};
  formValidations?: {};
  isMultipleRidesForm?: boolean;
  isMileageReport?: boolean;
};

const ReportsCommonFilterForm: FC<ReportsCommonFormProps> = ({
  open,
  onClose,
  onSubmit,
  theme,
  dataLoading = false,
  groups,
  objects,
  objectTypes,
  keys,
  submitting = false,
  fetchOnDebounce,
  onChange,
  isCO2InnerForm = false,
  formInitialValues,
  formValidations,
  isMultipleRidesForm = false,
  isMileageReport = false
}) => {
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  return (
    <Formik
      //@ts-ignore
      initialValues={formInitialValues}
      validationSchema={formValidations}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
        <Modal
          title="Filters"
          theme={theme}
          open={open}
          onClose={() => {
            onClose();
          }}
          secondaryBtnProps={{
            text: t("buttonsText.cancel"),
            onClick: onClose
          }}
          primaryBtnProps={{
            type: "submit",
            text: t("buttonsText.apply"),
            loading: isSubmitting || submitting,
            // @ts-ignore
            onClick: handleSubmit,
            disabled: !isValid
          }}
          size="customSize"
        >
          <InnerForm
            groups={groups}
            objectTypes={objectTypes}
            objects={objects}
            keys={keys}
            dataLoading={dataLoading}
            translator={t}
            fetchOnDebounce={fetchOnDebounce}
            onChange={onChange}
            //@ts-ignore
            values={values}
            setFieldValue={setFieldValue}
            isCO2InnerForm={isCO2InnerForm}
            isMultipleRidesForm={isMultipleRidesForm}
            isMileageReport={isMileageReport}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default ReportsCommonFilterForm;
