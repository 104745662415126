import { Table } from "@vilocnv/allsetra-core";
import { FC, memo } from "react";
import { ACTIVITY_DATE_RENDERING } from "app/data/constants";
import { useTranslation } from "react-i18next";
import ActivityFinalData from "../ActivityFinalData/ActivityFinalData";

interface ActivityExpendableRowCardProps {
  data: any;
}

const ActivityExpendableRowCard: FC<ActivityExpendableRowCardProps> = ({
  data
}) => {
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  return (
    <Table
      columns={ACTIVITY_DATE_RENDERING}
      data={data.records}
      expandableRows
      expandOnRowClicked
      expandableRowsComponent={ActivityFinalData}
      pagination={false}
      noTableHead={true}
      translator={t}
    />
  );
};

export default memo(ActivityExpendableRowCard);
