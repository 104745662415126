import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconBox, IconTooltip, IconsWrapper } from "../../Map.styled";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";

// Data
import {
  ActiveGeozoneIcon,
  ClusterIcon,
  ClusterIconBlack,
  GeozoneIcon,
  GeozoneIconBlack,
  TrafficIcon,
  TrafficIconBlack
} from "assets/icons";

interface Props {
  showSatelliteMode: boolean;
  setShowSatelliteMode: (value: any) => any;
  showClusters: boolean;
  showTraffic: boolean;
  setShowTraffic: (value: any) => any;
  handleClusterClick: () => void;
  showObjectNames: boolean;
  objectsMarker: boolean;
  setShowObjectNames: (value: any) => any;
  showGeozones: boolean;
  setShowGeozones: (value: any) => any;
  handleShowGeozoneClick: any;
  isDashboardMap?: boolean;
  showEnableDisableClustersButton?: boolean;
}

const MapRightTop: FC<Props> = ({
  showSatelliteMode,
  setShowSatelliteMode,
  showClusters,
  showEnableDisableClustersButton = true,
  showTraffic,
  setShowTraffic,
  handleClusterClick,
  showObjectNames,
  setShowObjectNames,
  objectsMarker,
  showGeozones,
  setShowGeozones,
  handleShowGeozoneClick,
  isDashboardMap
}) => {
  const { t } = useTranslation();

  return (
    <IconsWrapper>
      {/* TODO can be added later */}
      {/* {!objectsMarker && (
        <IconTooltip title={t("common.showObjectsNames")} arrow>
          <IconBox
            id="object-names"
            active={showObjectNames}
            onClick={() => setShowObjectNames((prevState: any) => !prevState)}
          >
            {showObjectNames ? <ShowObjectIconBlack /> : <ShowObjectIcon />}
          </IconBox>
        </IconTooltip>
      )} */}
      <IconTooltip title={t("common.showSatelliteMode")} arrow>
        <IconBox
          id="settelite-view"
          topMargin={false}
          active={showSatelliteMode}
          onClick={() =>
            setShowSatelliteMode((prevState: boolean) => !prevState)
          }
        >
          <SatelliteAltIcon
            sx={{
              color: showSatelliteMode ? "black" : "#959EB3"
            }}
            fontSize="small"
          />
        </IconBox>
      </IconTooltip>
      {showEnableDisableClustersButton && (
        <IconTooltip title={t("common.toggleCluster")} arrow>
          <IconBox
            id="toggle-traffic"
            active={showClusters}
            onClick={handleClusterClick}
          >
            {!showClusters ? <ClusterIcon /> : <ClusterIconBlack />}
          </IconBox>
        </IconTooltip>
      )}
      <IconTooltip title={t("common.toggleTraffic")} arrow>
        <IconBox
          id="toggle-cluster"
          active={showTraffic}
          onClick={() => setShowTraffic((prevState: boolean) => !prevState)}
        >
          {!showTraffic ? <TrafficIcon /> : <TrafficIconBlack />}
        </IconBox>
      </IconTooltip>
      {isDashboardMap && (
        <IconTooltip title={t("common.toggleGeozones")} arrow>
          <IconBox
            id="toggle-geozone"
            active={showGeozones}
            onClick={() => {
              setShowGeozones((prevState: boolean) => {
                if (prevState === false) handleShowGeozoneClick();
                return !prevState;
              });
            }}
          >
            {!showGeozones ? <GeozoneIcon /> : <GeozoneIconBlack />}
          </IconBox>
        </IconTooltip>
      )}
    </IconsWrapper>
  );
};

export default MapRightTop;
