import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, useTheme } from "@mui/material";
import {
  AllsetraShield,
  AllsetraTriangles,
  Circles,
  FietsgestolenTwoLogo,
  Plus
} from "assets/icons";
import {
  AllsetraShieldSection,
  CircleWrapper,
  FietsgeMainWrapper,
  FietsgestolenContainer,
  FirstSection,
  FirstSectionDescription,
  FirstSectionTitle,
  InnerWrapper,
  LogoInnerWrapper,
  LogoWrapper,
  MainGridWrapper,
  PlusWrapper,
  SecondSection,
  SecondSectionDescription,
  SecondSectionTitle,
  ShieldSectionDescription,
  ShieldSectionTitle,
  TrianglesWrapper
} from "../fietsgestolen.styled";
import { Button } from "@vilocnv/allsetra-core";
import { getJouwfietsgestolenPathnames } from "app/data/helpers/fietsgestolenHelpers";
import { Helmet } from "react-helmet";

const StartActivateTheft: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { pathnames } = getJouwfietsgestolenPathnames();

  return (
    <main>
      {/* @ts-ignore */}
      <Helmet>
        <title>JouwFietsBeveiligen</title>
        <meta
          name="description"
          content="YOUR BIKE IS YOURS, WE WILL KEEP IT THAT WAY"
        />
      </Helmet>
      <div>
        <PlusWrapper>
          <Plus />
        </PlusWrapper>
        <CircleWrapper>
          <Circles />
        </CircleWrapper>
      </div>
      <LogoWrapper>
        <TrianglesWrapper>
          <AllsetraTriangles />
        </TrianglesWrapper>
        <LogoInnerWrapper>
          <FietsgestolenTwoLogo />
        </LogoInnerWrapper>
      </LogoWrapper>
      <FietsgestolenContainer>
        <FietsgeMainWrapper>
          <InnerWrapper>
            <MainGridWrapper container columnSpacing={{ xs: 6, md: 25 }}>
              <Grid item xs={12} sm={6} md={6}>
                <FirstSection>
                  <FirstSectionTitle>
                    YOUR BIKE IS YOURS,<br></br> WE WILL KEEP IT THAT WAY
                  </FirstSectionTitle>
                  <FirstSectionDescription>
                    Goed dat je hebt gekozen om jouw fiets te beveiligen! Zo zet
                    je dieven buitenspel en heb je een grote kans dat je jouw
                    gestolen fiets weer terugkrijgt. Daarbij ontvang je een
                    diefstalbeveiligingscertificaat die je nodig hebt voor je
                    verzekering.
                    <div style={{ color: theme.palette.primary.main }}>
                      Om jouw fiets te beveiligen is het van belang dat je eerst
                      jouw diefstalbeveiliging activeert.
                    </div>
                  </FirstSectionDescription>
                </FirstSection>
                <SecondSection>
                  <SecondSectionTitle>Diefstal?</SecondSectionTitle>
                  <SecondSectionDescription>
                    Activeer eerst jouw diefstalbeveiliging en meld jouw
                    diefstal. Ons recherchebureau ABS-Tracer gaat in nauwe
                    samenwerking met de politie jouw gestolen fiets opsporen.
                    Dit doen ze al ruim 30 jaar met een terug vind percentage
                    van 97%
                  </SecondSectionDescription>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(pathnames.startReportTheftPathname)}
                    theme={theme}
                    color="error"
                  >
                    Diefstal melden
                  </Button>
                </SecondSection>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <AllsetraShieldSection>
                  <AllsetraShield />
                  <ShieldSectionTitle>
                    Activeren diefstalbeveiliging
                  </ShieldSectionTitle>
                  <ShieldSectionDescription>
                    Door het activeren van de diefstalbeveiliging weten wij en
                    de verzekeraar dat jouw fiets is beveiligd
                  </ShieldSectionDescription>
                  <Button
                    theme={theme}
                    onClick={() =>
                      navigate(pathnames.activateTheftProtectionFormPathname)
                    }
                    size="large"
                  >
                    Activeren diefstalbeveiliging
                  </Button>
                </AllsetraShieldSection>
              </Grid>
            </MainGridWrapper>
          </InnerWrapper>
        </FietsgeMainWrapper>
      </FietsgestolenContainer>
    </main>
  );
};

export default StartActivateTheft;
