import React, { FC, useEffect, useState } from "react";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { MarkerProps } from "./types";
import MarkerInfoWindowContent from "./MarkerInfoWindowContent";
import MarkerTooltip from "./MarkerTooltip";
import { useMapState } from "./MapStateContext";
import { useAppDispatch } from "hooks";
import { setActiveObjectId } from "app/features";
import { utils } from "@vilocnv/allsetra-core";

const Marker: FC<MarkerProps> = ({
  marker,
  clusterer,
  noClustererRedraw,
  showLinkIcon
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [closeInfoWindowClicked, setCloseInfoWindowClicked] = useState(false);
  const [icon, setIcon] = useState<google.maps.Icon | undefined>();
  const { activeObjectId } = useMapState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (marker) {
      const direction =
        marker?.direction && marker?.direction > 0
          ? marker?.direction
          : marker?.lastReportedDirection && marker?.lastReportedDirection > 0
            ? marker?.lastReportedDirection
            : marker?.lastReportedSpeed;

      const iconWithDirection = utils.getMapIcon(marker.iconUrl, direction);
      setIcon(iconWithDirection);
    }
  }, [marker]);

  useEffect(() => {
    if (!closeInfoWindowClicked) {
      setShowInfoWindow(activeObjectId === marker.uniqueId);
    }
  }, [activeObjectId, marker.uniqueId]);

  const handleCloseInfoWindowClick = () => {
    dispatch(setActiveObjectId(null));
    window.localStorage.removeItem("selectedObjectId");
    setShowInfoWindow(false);
    setCloseInfoWindowClicked(true);
  };

  const handleIconClick = () => {
    setShowInfoWindow(true);
    dispatch(setActiveObjectId(marker.uniqueId ?? ""));
  };

  return (
    <>
      <MarkerF
        position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
        onClick={() => handleIconClick()}
        icon={icon}
        onMouseOver={() => setIsTooltipVisible(true)}
        onMouseOut={() => setIsTooltipVisible(false)}
        clusterer={clusterer}
        noClustererRedraw={noClustererRedraw}
      >
        {showInfoWindow && (
          <InfoWindowF
            position={{ lat: marker.lat, lng: marker.lng }}
            onCloseClick={handleCloseInfoWindowClick}
          >
            <MarkerInfoWindowContent
              marker={{ ...marker }}
              showLinkIcon={showLinkIcon}
            />
          </InfoWindowF>
        )}
      </MarkerF>

      {isTooltipVisible && <MarkerTooltip marker={marker} />}
    </>
  );
};

export default React.memo(Marker);
