import { ScheduleReportsIcon } from "assets/icons";
import { FC, Fragment, useEffect, useState } from "react";
import { toast, useDispatchOnParams } from "@vilocnv/allsetra-core";
import { FormikHelpers } from "formik";
import ScheduleReportsForm from "components/forms/Reports/ScheduleReportsForm";
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import {
  selectDrawerSelectedAccountId,
  selectUsersState,
  selectScheduleReportsState,
  selectAccountKeysState,
  selectAccountsState
} from "app/data/selectors";
import {
  createOrEditReportScheduleThunk,
  getAllBasicInfoObjectsThunk,
  getAllKeysByAccountThunk,
  getReportExportTypesThunk,
  getReportFrequencyThunk,
  getUsersBasicInfoThunk,
  getUsersByQueryThunk,
  resetSpecificScheduleReport
} from "app/features";
import {
  convertTimeToISO,
  convertToUTC,
  reportTransformTimeForAPI
} from "app/data/helpers";
import { useTranslation } from "react-i18next";
import { ScheduleReportsText } from "./ScheduleReports.styled";
import { useTheme } from "@mui/material";

interface Props {
  reportType?: number;
  reportExportTypeValue?: number;
}

const ScheduleReports: FC<Props> = ({ reportType, reportExportTypeValue }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";
  const [openModal, setOpenModal] = useState(false);

  const {
    objects,
    basicObjects,
    objectsLoading,
    objectTypes,
    handleObjectsSearch
  } = useReportCommonStates();
  const { allUsers, loading: usersLoading } = useAppSelector(selectUsersState);
  const { reportFrequency, reportExportTypes } = useAppSelector(
    selectScheduleReportsState
  );

  const { usersBasicInfo } = useAppSelector(selectAccountsState);

  const { accountKeys } = useAppSelector(selectAccountKeysState);

  const scheduleReportsSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);
    const {
      object,
      objectType,
      reportType,
      key,
      daysOfWeek,
      startTime,
      endTime,
      deliveryTime,
      rideModes,
      ...rest
    } = values;

    let objectsData = object;
    if (values.assignedToAllObjects) {
      objectsData = [];
      // objectsData = basicObjects.map((item) => item.uniqueId);
    }

    let daysOfWeekValue = daysOfWeek;
    if (values.deliveryFrequencyType === 0) {
      daysOfWeekValue = [];
    }

    const payload = {
      reportDefinition: {
        object: objectsData,
        objectType,
        reportType,
        key,
        daysOfWeek: daysOfWeekValue,
        startTime: reportTransformTimeForAPI(startTime + ":00", undefined),
        endTime: reportTransformTimeForAPI(endTime + ":00", undefined),
        rideModes: values?.reportType === 2 ? rideModes?.map(Number) : []
      },
      deliveryTime: convertToUTC(deliveryTime),
      ...rest
    };

    const { type } = await dispatch(
      createOrEditReportScheduleThunk({
        accountId: drawerSelectedAccountId,
        data: payload
      })
    );

    if (type === "scheduleReports/createOrEditReportScheduleThunk/fulfilled") {
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm();
      toast.success("Schedule Created");
      setOpenModal(false);
    } else {
      toast.error("Server Error");
    }
  };

  useDispatchOnParams(getUsersByQueryThunk, {
    args: { accountId: drawerSelectedAccountId },
    searchByField: "user.firstName"
  });

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getReportFrequencyThunk());
      dispatch(getReportExportTypesThunk());
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
      dispatch(getAllBasicInfoObjectsThunk(drawerSelectedAccountId || ""));
      dispatch(getUsersBasicInfoThunk(drawerSelectedAccountId || ""));
    }
  }, [drawerSelectedAccountId]);

  return (
    <Fragment>
      <ScheduleReportsText
        onClick={() => {
          setOpenModal(true);
          dispatch(resetSpecificScheduleReport());
        }}
      >
        <ScheduleReportsIcon />
        {t("reports.schedule")}
      </ScheduleReportsText>
      <ScheduleReportsForm
        open={openModal}
        onClose={() => setOpenModal(false)}
        objects={[...basicObjects, ...objects]}
        dataLoading={objectsLoading}
        objectTypes={objectTypes}
        fetchOnDebounce={handleObjectsSearch}
        users={[...usersBasicInfo]}
        usersLoading={usersLoading}
        onSubmit={scheduleReportsSubmitHandler}
        reportFrequency={reportFrequency}
        keys={accountKeys}
        reportExportTypes={reportExportTypes}
        reportType={reportType}
        reportExportTypeValue={reportExportTypeValue}
        theme={theme}
      />
    </Fragment>
  );
};

export default ScheduleReports;
