import { FC, useEffect } from "react";
import { Box, Stack, useTheme } from "@mui/material";
import {
  FormikCheckbox,
  FormikInputField,
  FormikSelectField,
  FormikSelectSearchField
} from "@vilocnv/allsetra-core";
import ScheduleDaysFormats from "./ScheduleDaysFormats";
import { FormikValues } from "formik";
import {
  DELIVERY_FREQUENCY_OPTIONS,
  DELIVERY_TIME,
  EXPORT_TYPE_OPTIONS,
  REPORT_TYPES,
  RIDE_MODES
} from "app/data/constants";
import { TimeField, TimeFieldsWrapper } from "../ScheduleReportsForm.styled";
import { useFormikContext } from "formik";
import { sortObjectAlphabetically } from "app/data/helpers";

interface Props {
  objectTypes: Array<any>;
  objects: Array<any>;
  keys: Array<any>;
  dataLoading?: boolean;
  translator: any;
  fetchOnDebounce: (value: string) => void;
  onChange?: () => void;
  users?: Array<any>;
  usersLoading?: boolean;
  reportFrequency?: Array<any>;
  values: FormikValues;
  reportExportTypes?: Array<any>;
  reportType?: number;
  reportExportTypeValue?: number;
}

const InnerForm: FC<Props> = ({
  objectTypes,
  objects,
  keys,
  dataLoading = false,
  translator,
  fetchOnDebounce,
  onChange,
  users,
  // reportFrequency,
  values,
  // reportExportTypes,
  reportType,
  reportExportTypeValue
}) => {
  const theme = useTheme();

  const formik = useFormikContext();

  useEffect(() => {
    if (!values.uniqueId) {
      formik.setFieldValue("reportType", reportType);
      formik.setFieldValue("exportType", reportExportTypeValue);
    }
  }, [reportType, reportExportTypeValue]);

  return (
    <Stack spacing={2}>
      <FormikSelectField
        theme={theme}
        label={translator("usersEmail.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("usersEmail.placeholder", {
          ns: "formFieldsTranslation"
        })}
        name="recipients"
        options={users ?? []}
        optionLabelKey="email"
        optionValueKey="email"
        multiple
        searchable
        onChange={onChange}
        required
        fullWidth
      />
      <FormikInputField
        theme={theme}
        label={translator("scheduleName.label", {
          ns: "formFieldsTranslation"
        })}
        placeholder={translator("scheduleName.placeholder", {
          ns: "formFieldsTranslation"
        })}
        multiline
        name="body"
        rows={2}
        fullWidth
      />
      <FormikSelectField
        theme={theme}
        label={translator("selectReport.label", {
          ns: "formFieldsTranslation"
        })}
        name="reportType"
        options={REPORT_TYPES ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        required
        fullWidth
      />
      <FormikSelectField
        theme={theme}
        label={translator("selectReportFormat.label", {
          ns: "formFieldsTranslation"
        })}
        name="exportType"
        options={EXPORT_TYPE_OPTIONS(translator) ?? []}
        optionLabelKey="name"
        optionValueKey="id"
        required
        fullWidth
      />
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          justifyContent: "space-between",
          flex: 1
        }}
      >
        <Box sx={{ width: "100%" }}>
          <FormikSelectField
            theme={theme}
            label={translator("deliveryFrequency.label", {
              ns: "formFieldsTranslation"
            })}
            name="deliveryFrequencyType"
            options={DELIVERY_FREQUENCY_OPTIONS(translator) ?? []}
            optionLabelKey="name"
            optionValueKey="id"
            required
            fullWidth
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          <FormikSelectField
            theme={theme}
            label={translator("deliveryTime.label", {
              ns: "formFieldsTranslation"
            })}
            name="deliveryTime"
            options={DELIVERY_TIME ?? []}
            optionLabelKey="name"
            optionValueKey="uniqueId"
            required
            fullWidth
          />
        </Box>
      </Box>
      {/* @ts-ignore */}
      {formik?.values?.reportType === 2 ? (
        <FormikSelectField
          theme={theme}
          label={translator("rideMode", { ns: "formFieldsTranslation" })}
          placeholder={translator("rideMode", { ns: "formFieldsTranslation" })}
          name="rideModes"
          options={RIDE_MODES}
          optionLabelKey="name"
          optionValueKey="value"
          multiple
          searchable
          fullWidth
        />
      ) : null}
      <FormikCheckbox
        theme={theme}
        name="assignedToAllObjects"
        label={translator("selectAllObjects.label", {
          ns: "formFieldsTranslation"
        })}
      />
      {!values.assignedToAllObjects && (
        <FormikSelectSearchField
          fetchOnDebounce={fetchOnDebounce}
          label={translator("objects.label", { ns: "formFieldsTranslation" })}
          placeholder={translator("objects.placeholder", {
            ns: "formFieldsTranslation"
          })}
          name="object"
          options={sortObjectAlphabetically(objects) ?? []}
          optionLabelKey="name"
          optionValueKey="uniqueId"
          loading={dataLoading}
          multiple
          searchable
          fullWidth
          onChange={onChange}
        />
      )}
      <FormikSelectField
        theme={theme}
        label={translator("objectsTypes.label", {
          ns: "formFieldsTranslation"
        })}
        placeholder={translator("objectsTypes.placeholder", {
          ns: "formFieldsTranslation"
        })}
        name="objectType"
        options={objectTypes ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        multiple
        searchable
        fullWidth
      />
      <FormikSelectField
        theme={theme}
        label={translator("keys.label", {
          ns: "formFieldsTranslation"
        })}
        name="key"
        options={keys ?? []}
        optionLabelKey="label"
        optionValueKey="uniqueId"
        multiple
        searchable
        fullWidth
      />
      {values.deliveryFrequencyType > 0 && <ScheduleDaysFormats />}
      <TimeFieldsWrapper>
        <TimeField>
          <span>
            {translator("from", {
              ns: "formFieldsTranslation"
            })}
          </span>
          <FormikInputField
            theme={theme}
            type={"time"}
            name="startTime"
            format="12"
          />
        </TimeField>
        <TimeField>
          <span>
            {translator("to", {
              ns: "formFieldsTranslation"
            })}
          </span>
          <FormikInputField
            theme={theme}
            type={"time"}
            name="endTime"
            format="24"
          />
        </TimeField>
      </TimeFieldsWrapper>
    </Stack>
  );
};

export default InnerForm;
