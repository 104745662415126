import { FC } from "react";
import { Button } from "@vilocnv/allsetra-core";
import { useNavigate } from "react-router-dom";
import { DesignVector, SuccessIcon } from "assets/icons";
import {
  DesignContainer,
  LogoTitle,
  SuccessButtonContainer,
  SuccessMessageContainer,
  Title,
  Subtitle
} from "./ActivationSuccessSection.styled";
import { useAppSelector } from "hooks";
import { selectActivationPortalState } from "app/data/selectors/activationPortalSelectors";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

interface Props {
  gotoNextStep: () => void;
}

const ActivationSuccessSection: FC<Props> = ({ gotoNextStep }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation(["translation", "activationPortal"]);

  const { fromDashboard } = useAppSelector(selectActivationPortalState);

  const handleSuccessButton = () => {
    gotoNextStep();
    navigate("/dashboard/objects");
  };

  return (
    <div>
      <DesignContainer>
        <DesignVector />
        <DesignVector />
      </DesignContainer>
      <SuccessMessageContainer>
        <SuccessIcon />
        <Title>{t("titles.congratulations", { ns: "activationPortal" })}</Title>
        <Subtitle>
          {t("descriptions.congratulations", { ns: "activationPortal" })}
        </Subtitle>
        <Subtitle>
          {t("descriptions.confirmationEmail", { ns: "activationPortal" })}
        </Subtitle>
        <LogoTitle>Team Allsetra</LogoTitle>
      </SuccessMessageContainer>
    </div>
  );
};

export default ActivationSuccessSection;
