import { FC, Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { Button } from "@vilocnv/allsetra-core";
import BasicPageLayout from "components/layouts/BasicPageLayout";
import { CtaText } from "../activationPortal.styled";

// MSAL
import { UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { resetVerificationCodeLoading, setFromDashboard } from "app/features";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectWhiteLabelsState } from "app/data/selectors";

const StartActivation: FC = () => {
  const theme = useTheme();
  const params = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const { whiteLabel, loading } = useAppSelector(selectWhiteLabelsState);

  const { t, i18n } = useTranslation(["translation", "activationPortal"]);

  useEffect(() => {
    // Change the language to Dutch
    i18n.changeLanguage("nl");
  }, []);

  useEffect(() => {
    if (isAuthenticated || params.search.includes("fromDashboard")) {
      dispatch(setFromDashboard(true));
    } else {
      dispatch(setFromDashboard(false));
    }
  }, [params, isAuthenticated]);

  const handleSubmit = () => {
    dispatch(resetVerificationCodeLoading());
    navigate("/activation-portal/verify-code");
  };

  return (
    <BasicPageLayout
      title={t("titles.activationPortal", { ns: "activationPortal" })}
      subtitle={t("descriptions.activateDevice", { ns: "activationPortal" })}
      whiteLabelBgImage={whiteLabel?.backgroundImageUrl}
      loading={loading}
    >
      <Fragment>
        <Button
          fullWidth={true}
          theme={theme}
          disabled={false}
          onClick={handleSubmit}
        >
          {t("buttons.start", { ns: "activationPortal" })}
        </Button>
        {/* removing the below login CTA section as per Michael's request  */}
        {/* <UnauthenticatedTemplate>
          <CtaText>
            {t("buttons.alreadyHaveAccount", { ns: "activationPortal" })}
            <span onClick={() => navigate("/")}>Log In</span>
          </CtaText>
        </UnauthenticatedTemplate> */}
      </Fragment>
    </BasicPageLayout>
  );
};

export default StartActivation;
