import { Box, styled } from "@mui/material";

export const ObjectInfo = styled(Box)<{ bottomPadding?: boolean }>(
  ({ bottomPadding }) => ({
    backgroundColor: "#1D1F2BB8",
    color: "#fff",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14.4px",
    padding: bottomPadding ? "10px" : "10px 10px 0 10px",
    borderRadius: "4px",
    marginLeft: 10
  })
);

export const MultipleRidesMapContainer = styled(Box)({
  height: "calc(100vh - 170px)",
  width: "100%",
  position: "relative",

  ".gm-style-iw.gm-style-iw-c": {
    background: "none",
    boxShadow: "none"
  },
  "button.gm-ui-hover-effect": {
    top: "6px !important",
    "& span": {
      backgroundColor: "#ffffff !important"
    }
  },
  ".gm-style-iw-d": {
    overflow: "hidden !important"
  },
  ".gm-style .gm-style-iw-tc::after": {
    background: "#192026E0"
  },
  ".gmnoprint .gm-style-mtc": {
    display: "none"
  },
  "button.gm-control-active.gm-fullscreen-control": {
    display: "none"
  }
});

export const ObjectListContainer = styled(Box)({
  position: "absolute",
  top: "10px",
  left: "10px",
  backgroundColor: "white",
  borderRadius: "4px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  overflowY: "auto",
  border: "1px solid #D5D9E8",
  zIndex: 400,
  padding: "17px",
  width: "360px",
  maxHeight: "690px"
});

export const ObjectList = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "18px",
  justifyContent: "space-between"
});

export const ObjectText = styled(Box)({
  fontFamily: "Gilroy",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "110%",
  marginLeft: "7px",
  width: "235px"
});

export const ObjectDateText = styled(Box)({
  fontFamily: "Gilroy",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "110%",
  color: "#1D1F2B",
  marginLeft: "3px",
  marginTop: "2px"
});

export const ObjectItem = styled(Box)<{ active?: boolean }>(({ active }) => ({
  padding: "14px 10px 0 10px",
  borderColor: "#A3E7E1",
  borderStyle: "solid",
  borderRightWidth: "0px",
  borderLeftWidth: "0px",
  borderTopWidth: "0.5px",
  borderBottomWidth: "0.5px",
  backgroundColor: active ? "#F5FFFE" : "transparent",
  "&:hover": {
    cursor: "pointer"
  }
}));

export const ObjectBorder = styled(Box)<{ active?: boolean }>(({ active }) => ({
  height: "0.5px",
  width: "100%",
  backgroundColor: active ? "#A3E7E1" : "#DFE5F4"
}));

export const ObjectColorBox = styled(Box)<{ color: string }>(({ color }) => ({
  height: "14px",
  width: "14px",
  borderRadius: "2px",
  backgroundColor: color,
  marginRight: "8px"
}));

export const IconWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  fontSize: "12px",
  lineHeight: "14.4px",
  color: "#fff",
  paddingBottom: "10px"
});

export const CloseButton = styled(Box)({
  position: "absolute",
  top: "10px",
  left: "377px",
  backgroundColor: "white",
  borderRadius: "4px",
  height: "32px",
  width: "32px",
  zIndex: 500,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
});

export const ShowRideButton = styled(Box)({
  fontFamily: "Gilroy",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  backgroundColor: "white",
  borderRadius: "4px",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  width: "110px",
  height: "35px",
  border: "1px solid #D5D9E8"
});
