export const updateFavicon = (faviconUrl?: string) => {
  let favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;

  if (!favicon) {
    favicon = document.createElement("link");
    favicon.rel = "icon";
    document.head.appendChild(favicon);
  }

  favicon.href = faviconUrl || "/favicon.ico";
};
