import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import PhoneInput from "components/ui/inputs/PhoneInput/PhoneInput";
import {
  TwoColsLayout,
  FormikInputField,
  FormikSelectField
} from "@vilocnv/allsetra-core";

export interface AddressFormSectionProps {
  title: string;
  parentKey: string;
  hideDivider?: boolean;
  countries: Array<any>;
  countriesLoading?: boolean;
  translator?: any;
}

const AddressFormSection: FC<AddressFormSectionProps> = ({
  title,
  parentKey,
  hideDivider = false,
  countries,
  countriesLoading,
  translator
}) => {
  return (
    <Fragment>
      <TwoColsLayout title={title}>
        <FormikInputField
          label={
            translator
              ? translator("street.label", {
                  ns: "formFieldsTranslation"
                })
              : "Street"
          }
          placeholder={
            translator
              ? translator("street.placeholder", {
                  ns: "formFieldsTranslation"
                })
              : "Street"
          }
          name={`${parentKey}.street`}
          fullWidth
          required
        />
        <Stack direction={"row"} spacing={2}>
          <FormikInputField
            label={
              translator
                ? translator("houseNumber.label", {
                    ns: "formFieldsTranslation"
                  })
                : "House number"
            }
            placeholder={
              translator
                ? translator("houseNumber.placeholder", {
                    ns: "formFieldsTranslation"
                  })
                : "House number"
            }
            name={`${parentKey}.houseNumber`}
            fullWidth
            required
          />
          <FormikInputField
            label={
              translator
                ? translator("extension.label", {
                    ns: "formFieldsTranslation"
                  })
                : "Extension"
            }
            placeholder={
              translator
                ? translator("extension.placeholder", {
                    ns: "formFieldsTranslation"
                  })
                : "Extension"
            }
            name={`${parentKey}.extension`}
            fullWidth
          />
        </Stack>
        <FormikInputField
          label={
            translator
              ? translator("postalCode.label", {
                  ns: "formFieldsTranslation"
                })
              : "Postal code"
          }
          placeholder={
            translator
              ? translator("postalCode.placeholder", {
                  ns: "formFieldsTranslation"
                })
              : "Postal code"
          }
          name={`${parentKey}.postalCode`}
          fullWidth
          required
        />
        <FormikInputField
          label={
            translator
              ? translator("city.label", {
                  ns: "formFieldsTranslation"
                })
              : "City"
          }
          placeholder={
            translator
              ? translator("city.placeholder", {
                  ns: "formFieldsTranslation"
                })
              : "City"
          }
          name={`${parentKey}.city`}
          fullWidth
          required
        />
        <FormikSelectField
          label={
            translator
              ? translator("country.label", {
                  ns: "formFieldsTranslation"
                })
              : "Country"
          }
          placeholder={
            translator
              ? translator("country.placeholder", {
                  ns: "formFieldsTranslation"
                })
              : "Country"
          }
          options={countries}
          optionLabelKey={"name"}
          optionValueKey={"id"}
          name={`${parentKey}.countryId`}
          loading={countriesLoading}
          searchable
          fullWidth
        />
      </TwoColsLayout>
      <TwoColsLayout hideDivider={hideDivider}>
        <FormikInputField
          label={
            translator
              ? translator("email.label", {
                  ns: "formFieldsTranslation"
                })
              : "Email"
          }
          placeholder="Email"
          name={`${parentKey}.email`}
          fullWidth
          required
        />
        <PhoneInput
          label={
            translator
              ? translator("phoneNumber.label", {
                  ns: "formFieldsTranslation"
                })
              : "Phone number"
          }
          translator={translator}
          phoneInputName={`${parentKey}.phoneNumber`}
          countryInputName={`${parentKey}.countryCode`}
          required
        />
      </TwoColsLayout>
    </Fragment>
  );
};

export default AddressFormSection;
