import { useCallback, useState } from "react";

interface UseMovementRideReturn {
  showMovementRides: boolean;
  handleMovementRidesChange: (checked: boolean) => void;
}

const useMovementRides = (
  initialValue: boolean = false
): UseMovementRideReturn => {
  const [showMovementRides, setShowMovementRides] =
    useState<boolean>(initialValue);

  const handleMovementRidesChange = useCallback((checked: boolean) => {
    setShowMovementRides(checked);
  }, []);

  return {
    showMovementRides,
    handleMovementRidesChange
  };
};

export default useMovementRides;
