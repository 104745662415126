import React, { FC, Fragment, useMemo } from "react";

import { PolylineF } from "@react-google-maps/api";
import { objectColors } from "app/data/helpers";
import { GroupedRide } from "./types";
import { utils } from "@vilocnv/allsetra-core";

interface PolylineProps {
  groupedRides: GroupedRide[];
  highlightRideId: string | undefined;
  onClick?: (objectId: string, rideUniqueId: string) => void;
}

const MultiRidesPolyline: FC<PolylineProps> = ({
  groupedRides,
  highlightRideId,
  onClick
}: PolylineProps) => {
  const renderPolyline = useMemo(() => {
    return groupedRides.map((groupedRide, index) => {
      const objectIndex = index;
      const objectId = groupedRide?.objectId;
      const items = groupedRide?.items ?? [];

      return items.map((item, index) => {
        const rideUniqueId = item.rideUniqueId;
        const path = item.rideLocations.map(
          (ride: { latitude: number; longitude: number }) => ({
            lat: ride.latitude ?? 0,
            lng: ride.longitude ?? 0
          })
        );
        const isHighlighted = rideUniqueId === highlightRideId;
        const mainColor = isHighlighted ? "#498feb" : objectColors[objectIndex];
        const darkenedColor = utils.darkenColor(mainColor, 20);

        const mainOptions = {
          strokeColor: mainColor,
          strokeWeight: 10,
          zIndex: isHighlighted ? 2 : 1
        };
        const borderOptions = {
          strokeColor: darkenedColor,
          strokeWeight: 16,
          zIndex: isHighlighted ? 1 : 0,
          strokeOpacity: 1
        };

        return (
          <Fragment key={index}>
            <PolylineF
              key={`${index}-border`}
              path={path}
              options={borderOptions}
            />
            <PolylineF
              key={`${index}-main`}
              path={path}
              options={mainOptions}
              onClick={() => onClick && onClick(objectId, rideUniqueId)}
            />
          </Fragment>
        );
      });
    });
  }, [groupedRides, highlightRideId]);

  return <>{groupedRides.length > 0 && <>{renderPolyline}</>}</>;
};

export default React.memo(MultiRidesPolyline);
