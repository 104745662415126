import { FC, useEffect } from "react";
import {
  ContentSectionLayout,
  TwoColsLayout,
  FormikInputField,
  FormikSelectField,
  utils,
  FormikSelectSearchField
} from "@vilocnv/allsetra-core";
import { ChildFormBox } from "../ObjectSettingsForm.styled";
import { LockedIcon } from "assets/icons";

import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectAccountGroups,
  selectAccountsState,
  selectDrawerSelectedAccountId,
  selectObjectTypesState
} from "app/data/selectors";
import {
  getAllAccountGroupsThunk,
  getAllAccountsThunk,
  getObjectTypesByAccountThunk,
  postAccountsMinimalSearch
} from "app/features";
import { flattenAccounts } from "app/data/helpers";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

interface Props {
  activeObject: any | null;
}

const ObjectInformationSetting: FC<Props> = ({ activeObject }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const { selectedAccounts, loading: accountsLoading } =
    useAppSelector(selectAccountsState);

  const flattenedAccounts = flattenAccounts(selectedAccounts);

  const { accountGroups, loading: groupsLoading } =
    useAppSelector(selectAccountGroups);

  const { objectTypes, loading: objectTypesLoading } = useAppSelector(
    selectObjectTypesState
  );

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const handleAccountsSearch = (value: string) => {
    dispatch(
      postAccountsMinimalSearch({
        itemsPerPage: 50,
        page: 1,
        where: [{ field: "name", value, type: 0 }]
      })
    );
  };

  useEffect(() => {
    dispatch(postAccountsMinimalSearch(utils.getCommonParamsForApi()));
    dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId || ""));
    dispatch(getObjectTypesByAccountThunk(drawerSelectedAccountId || ""));
  }, []);

  return (
    <ContentSectionLayout
      title={t("titles.objectInfo")}
      subTitle={t("descriptions.objectInfo")}
    >
      <ChildFormBox>
        <TwoColsLayout>
          <FormikInputField
            theme={theme}
            label={t("objectName.label", { ns: "formFieldsTranslation" })}
            placeholder={t("objectName.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="name"
            fullWidth
          />
          <FormikSelectField
            theme={theme}
            label={t("objectType.label", { ns: "formFieldsTranslation" })}
            placeholder={t("objectType.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="objectTypeId"
            options={objectTypes ?? []}
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={objectTypesLoading}
            fullWidth
          />
        </TwoColsLayout>

        <TwoColsLayout>
          <FormikSelectSearchField
            fetchOnDebounce={handleAccountsSearch}
            theme={theme}
            label={t("assignedAccounts.label", { ns: "formFieldsTranslation" })}
            placeholder={t("assignedAccounts.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="accounts"
            options={flattenedAccounts ?? []}
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={accountsLoading}
            searchable
            multiple
            fullWidth
          />
          <FormikSelectField
            theme={theme}
            label={t("groups.label", { ns: "formFieldsTranslation" })}
            placeholder={t("groups.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="groups"
            options={accountGroups ?? []}
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={groupsLoading}
            searchable
            multiple
            fullWidth
          />
        </TwoColsLayout>

        <TwoColsLayout>
          <FormikInputField
            theme={theme}
            label={t("alarmOwner.label", { ns: "formFieldsTranslation" })}
            placeholder={t("alarmOwner.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="alarmOwner"
            fullWidth
            disabled
            endIcon={<LockedIcon />}
          />
          <FormikInputField
            theme={theme}
            label={t("invoiceOwner.label", { ns: "formFieldsTranslation" })}
            placeholder={t("invoiceOwner.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="invoiceOwner"
            fullWidth
            disabled
            endIcon={<LockedIcon />}
          />
        </TwoColsLayout>

        <TwoColsLayout hideDivider>
          <FormikInputField
            theme={theme}
            label={t("id.name", {
              ns: "formFieldsTranslation"
            })}
            name="aNumber"
            placeholder={t("id.placeholder", {
              ns: "formFieldsTranslation"
            })}
            fullWidth
            disabled
            endIcon={<LockedIcon />}
          />
          <FormikInputField
            theme={theme}
            label={t("multiviewerName.name", {
              ns: "formFieldsTranslation"
            })}
            name="multiviewerName"
            placeholder={t("multiviewerName.placeholder", {
              ns: "formFieldsTranslation"
            })}
            fullWidth
            disabled
            endIcon={<LockedIcon />}
          />
        </TwoColsLayout>
      </ChildFormBox>
    </ContentSectionLayout>
  );
};

export default ObjectInformationSetting;
