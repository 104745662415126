import React, { FC, Fragment } from "react";
import { OverlayView, OverlayViewF } from "@react-google-maps/api";
import { IconWrapper, ObjectInfo } from "./MultipleRidesGoogleMap.styled";

import { Box } from "@mui/material";
import { DateTime } from "luxon";

import { SpeedIconWhite, CalendarIconWhite } from "assets/icons";
import { MarkerBlip } from "../Map/Map.styled";
import { types, utils } from "@vilocnv/allsetra-core";

interface MarkerTooltipProps {
  marker: types.IObjectRideLocation;
  objectName: string;
  isDotMarker: boolean;
}

const MultipleRidesMarkerTooltip: FC<MarkerTooltipProps> = ({
  marker,
  objectName,
  isDotMarker
}) => {
  return (
    <OverlayViewF
      position={{
        lat: marker.latitude,
        lng: marker.longitude
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={() =>
        isDotMarker ? { x: 15, y: -15 } : { x: 10, y: -30 }
      }
    >
      <Fragment>
        {!isDotMarker ? (
          <ObjectInfo bottomPadding>
            {`${objectName} ${utils.formatDate({ date: marker.date, whenEmpty: "N/A" })}`}
          </ObjectInfo>
        ) : (
          <ObjectInfo>
            <IconWrapper>
              <CalendarIconWhite />
              <Box>
                {utils.formatDate({ date: marker.date, whenEmpty: "N/A" })}
              </Box>
            </IconWrapper>
            <IconWrapper>
              <SpeedIconWhite />
              <Box>{marker.speed ?? "N/A"} km/h</Box>
            </IconWrapper>
          </ObjectInfo>
        )}
        <MarkerBlip></MarkerBlip>
      </Fragment>
    </OverlayViewF>
  );
};

export default React.memo(MultipleRidesMarkerTooltip);
