import React, { FC, useEffect, useMemo, useState } from "react";
import { MapStateProvider } from "components/maps/Map/v2/GoogleMap/MapStateContext";
import { types } from "@vilocnv/allsetra-core";
import { selectObjectMapState } from "app/data/selectors";
import { useAppSelector } from "hooks";
import ObjectDetailsGoogleMap from "./ObjectDetailsGoogleMap";

interface Props {
  object: types.IObject | null;
}

const ObjectDetailsMap: FC<Props> = ({ object }) => {
  const { markers } = useAppSelector(selectObjectMapState);
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (markers.length > 0) {
      const marker = markers.find((m) => m.uniqueId === object?.uniqueId);
      setMarker(marker);
    }
  }, [markers, object]);

  const objectLocation = useMemo(
    () => ({
      lat: object?.location?.latitude || 0,
      lng: object?.location?.longitude || 0
    }),
    [object]
  );

  const objectRadius = useMemo(
    () =>
      object?.location?.accuracy
        ? object.location.accuracy > 15
          ? object.location.accuracy
          : 0
        : 0,
    [object]
  );

  return (
    <>
      {marker && (
        <MapStateProvider
          center={objectLocation}
          radius={objectRadius}
          zoom={10}
          height="75vh"
          objects={[object]}
          isLoading={false}
          disableNavigator={true}
          skipCurrentLocation={true}
          showSearch={true}
          showFilter={true}
          isDashboardMap={true}
          objectsMarker={true}
          markers={[marker]}
        >
          <ObjectDetailsGoogleMap activeObject={object} />
        </MapStateProvider>
      )}
    </>
  );
};

export default ObjectDetailsMap;
