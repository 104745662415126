import React, { FC, Fragment, useMemo } from "react";

import MultiRidesMarker from "./MultiRidesMarker";
import { GroupedRide } from "./types";
import { utils } from "@vilocnv/allsetra-core";

const circle = require("../../../assets/icons/common/CircleIcon.svg").default;

interface MakersProps {
  groupedRides: GroupedRide[];
  onClick?: (objectId: string, rideUniqueId: string) => void;
}

const MultiRidesMarkers: FC<MakersProps> = ({
  groupedRides,
  onClick
}: MakersProps) => {
  const getRideIcon = (
    iconUrl: string,
    index: number,
    groupLength: number,
    direction: number
  ) => {
    if (index === 0) {
      const startIcon = utils.getMapIcon(
        `${iconUrl.split(".png")[0]}-green.png`,
        direction
      );
      return startIcon!;
    }

    if (index === groupLength - 1) {
      const endIcon = utils.getMapIcon(
        `${iconUrl.split(".png")[0]}-red.png`,
        direction
      );
      return endIcon!;
    }
    return {
      url: circle,
      scaledSize: new google.maps.Size(10, 10),
      anchor: new google.maps.Point(5, 5)
    };
  };

  const renderMarkers = useMemo(() => {
    return groupedRides.map((groupedRide) => {
      const iconUrl = groupedRide.objectIcon;
      const items = groupedRide?.items ?? [];
      const objectName = groupedRide?.objectName;
      const objectId = groupedRide?.objectId;

      return items.map((item) => {
        const rides = item?.rideLocations ?? [];
        const rideUniqueId = item.rideUniqueId;

        return rides.map((ride, index) => {
          const icon = getRideIcon(
            iconUrl,
            index,
            rides.length,
            ride.direction ? +ride.direction : 0
          );
          const isDotMarker = index !== 0 && index !== rides.length - 1;

          return (
            <Fragment key={index}>
              <MultiRidesMarker
                objectName={objectName}
                marker={ride}
                icon={icon}
                isDotMarker={isDotMarker}
                onClick={() => onClick && onClick(objectId, rideUniqueId)}
              />
            </Fragment>
          );
        });
      });
    });
  }, [groupedRides]);

  return <>{groupedRides.length > 0 && <>{renderMarkers}</>}</>;
};

export default React.memo(MultiRidesMarkers);
