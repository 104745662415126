import { FC, useEffect, useRef, useState } from "react";
import { useMapState } from "../Map/v2/GoogleMap/MapStateContext";
import GoogleMap from "../Map/v2/GoogleMap";
import {
  getBoundsZoomLevel,
  getElementSize,
  getGroupedRides
} from "app/data/helpers";
import MultiRidesMarkers from "./MultiRidesMarkers";
import MultiRidesPolyline from "./MultiRidesPolyline";
import MultipleRidesTopRightSection from "./MultipleRidesTopRightSection";
import MultipleRidesObjectsListModal from "./MultipleRidesObjectsListModal";
import { GroupedRide, MultipleRidesGoogleMapProps } from "./types";
import { MultipleRidesMapContainer } from "./MultipleRidesGoogleMap.styled";

const MultipleRidesGoogleMap: FC<MultipleRidesGoogleMapProps> = ({
  objectsRides
}) => {
  const { mapRef, isLoaded } = useMapState();
  const alarmContainerRef = useRef<HTMLDivElement>();
  const [groupedRides, setGroupedRides] = useState<GroupedRide[]>([]);
  const [highlightRideId, setHighlightRideId] = useState<string>();
  const [showRideModal, setShowRideModal] = useState<boolean>(false);

  useEffect(() => {
    if (objectsRides.length > 0 && isLoaded) {
      centerMap();
      groupRides();
      setHighlightRideId(undefined);
    }
  }, [objectsRides, isLoaded]);

  const centerMap = () => {
    const allCoordinates = objectsRides.flatMap((or) =>
      or.items.flatMap((item) =>
        item.rideLocations.map((rl) => ({
          latitude: rl.latitude ?? 0,
          longitude: rl.longitude ?? 0
        }))
      )
    );

    const mapDim = getElementSize(alarmContainerRef.current);
    const zoomLevel = getBoundsZoomLevel(allCoordinates, mapDim);

    mapRef?.setZoom(zoomLevel || 11);

    const bounds = new google.maps.LatLngBounds();
    allCoordinates.forEach((coord) => {
      bounds.extend({ lat: coord.latitude, lng: coord.longitude });
    });
    const center = bounds.getCenter();

    mapRef?.panTo(center);
  };

  const groupRides = async () => {
    if (!objectsRides || objectsRides.length <= 0) return;

    setGroupedRides(objectsRides);
  };

  const highlightPath = (objectId: string, rideUniqueId: string) => {
    const object = groupedRides.find(
      (groupRide) => groupRide.objectId === objectId
    );
    const rides = object?.items.find(
      (objectItem) => objectItem.rideUniqueId === rideUniqueId
    );
    const ridesLocation = rides?.rideLocations ?? [];
    const path = ridesLocation.map(
      (ride: { latitude: number; longitude: number }) => ({
        latitude: ride.latitude ?? 0,
        longitude: ride.longitude ?? 0
      })
    );

    const zoomLevel = getBoundsZoomLevel(
      path,
      getElementSize(alarmContainerRef.current)
    );

    if (zoomLevel) {
      mapRef?.setZoom(zoomLevel - 1);
    }

    const { latitude, longitude } = ridesLocation[0];
    mapRef?.panTo({ lat: latitude, lng: longitude });

    setHighlightRideId(rideUniqueId);
  };

  const toggleShowModal = () => {
    setShowRideModal((prevState) => !prevState);
  };

  return (
    <MultipleRidesMapContainer ref={alarmContainerRef}>
      <GoogleMap>
        <MultipleRidesTopRightSection
          showRidesModal={showRideModal}
          onShowRidesButtonClick={toggleShowModal}
        />
        <MultipleRidesObjectsListModal
          groupedRides={groupedRides}
          showRidesModal={showRideModal}
          highlightRideId={highlightRideId}
          onItemClick={highlightPath}
          onCloseButtonClick={toggleShowModal}
        />
        <MultiRidesMarkers
          groupedRides={groupedRides}
          onClick={highlightPath}
        />
        <MultiRidesPolyline
          groupedRides={groupedRides}
          onClick={highlightPath}
          highlightRideId={highlightRideId}
        />
      </GoogleMap>
    </MultipleRidesMapContainer>
  );
};

export default MultipleRidesGoogleMap;
