import { isEmpty } from "lodash";
import {
  type AuthenticationResult,
  InteractionRequiredAuthError,
  SilentRequest
} from "@azure/msal-browser";
import { msalInstance } from "app/integrations/azure/authConfig";
import { toast } from "@vilocnv/allsetra-core";
import * as Sentry from "@sentry/react";
import { clearCache } from "../../store";
import { clearIndexedDB } from "app/data/helpers";

class AzureMsal {
  static tokenCache: {
    tokenResponse: AuthenticationResult | undefined;
    expiration: number | undefined;
  } = {
    tokenResponse: undefined,
    expiration: undefined
  };

  static isTokenExpired({
    tokenResponse,
    expiration
  }: {
    tokenResponse: AuthenticationResult | undefined;
    expiration: number | undefined;
  }): boolean {
    if (!tokenResponse || !expiration) {
      return true;
    }
    return Date.now() / 1000 > expiration!;
  }

  static acquireToken = async () => {
    if (!this.isTokenExpired({ ...this.tokenCache })) {
      return this.tokenCache.tokenResponse;
    }

    const account = msalInstance.getAllAccounts()[0];
    const redirectUri = window.location.origin;

    const accessTokenRequest: SilentRequest = {
      scopes: [
        "openid",
        "profile",
        "offline_access",
        "https://graph.microsoft.com/.default"
      ],
      account: account,
      redirectUri: redirectUri
    };

    try {
      const accessTokenResponse =
        await msalInstance.acquireTokenSilent(accessTokenRequest);

      const exp = accessTokenResponse.account?.idTokenClaims?.exp;

      if (exp && exp < Date.now() / 1000) {
        AzureMsal.logoutUser({ showToast: true });
      }

      this.tokenCache.tokenResponse = accessTokenResponse;
      this.tokenCache.expiration = exp;

      return accessTokenResponse;
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error acquiring token silently:", error);

      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenRedirect(accessTokenRequest);
      } else {
        // AzureMsal.logoutUser({ showToast: true });
        this.tokenCache.tokenResponse = undefined;
        this.tokenCache.expiration = undefined;
      }
    }

    return null;
  };

  static logoutOnTokenExp = () => {
    const account = msalInstance.getActiveAccount();

    if (isEmpty(account)) {
      AzureMsal.logoutUser({ showToast: true });
    } else {
      const exp: number | undefined = account.idTokenClaims?.exp;

      if (exp && exp < Date.now() / 1000) {
        AzureMsal.logoutUser({ showToast: true });
      }
    }
  };

  static logoutUser = async ({ showToast }: { showToast: boolean }) => {
    if (showToast) {
      toast.info("User session is expired.");
    }

    try {
      await clearIndexedDB(); // Clear Indexed DB
      console.log("IndexedDB cleared successfully");
    } catch (error) {
      console.error("Error clearing IndexedDB:", error);
    }

    try {
      await clearCache();
      console.log("Redux persist cache cleared successfully");
    } catch (error) {
      console.error("Error clearing Redux persist cache:", error);
    }

    Sentry.setUser(null);

    window.localStorage.clear();
    window.sessionStorage.clear();

    this.tokenCache.tokenResponse = undefined;
    this.tokenCache.expiration = undefined;

    // Close any open connections to IndexedDB
    indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => {
        //@ts-ignore
        indexedDB.deleteDatabase(db.name);
      });
    });

    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };
}

export default AzureMsal;
